import '../App.css';
import theGraphLogo from '../images/thegraph/thegraph-email-logo.png';

function TheGraph() {
  return (
    <div className="App-background-colour">
      <div className="container App-background-colour text-white">
          <div className="row">
            <div className="col"><h1>&nbsp;</h1></div>
          </div>
          <div className="row">
            <div className="col text-center">
              <a href="https://thegraph.com">
                <img className="project-logo" src={theGraphLogo} alt="The Graph" width="400px" />
              </a>
            </div>
          </div>
          <div className="row">
            <div className="col"><h3>&nbsp;</h3></div>
          </div>
          <div className="row">
            <div className="col text-center">
              <h4>counterpoint are very proud to be participating as an indexer in The Graph network</h4>
            </div>
          </div>
          <div className="row">
            <div className="col"><h3>&nbsp;</h3></div>
          </div>
          <div className="row">
            <div className="col">
              <div className="card text-black text-center">
                <div className="card-header">
                  <h3 className="card-title">Achievements so far</h3>
                </div>
                <div className="card-body">
                  <ul className="list-group">
                    <li className="list-group-item">2020-2021 Ran devops for an indexing team in Mission Control (the incentivised testnet)</li>
                    <li className="list-group-item">2022-09-20 Joined the MIPS program to become a fully fledged indexer</li>
                    <li className="list-group-item">2022-09-25 Testnet indexer <a className="link-primary" href="https://testnet.thegraph.com/explorer/profile/0x75aea8ac91ce8c60430d5e6d399c7a29c52f6574?view=Indexing">counterpoint-indexer.eth</a> created on Goerli</li>
                    <li className="list-group-item">2022-10-20 Mainnet indexer <a className="link-primary" href="https://thegraph.com/explorer/profile?id=0x745cf2b4e7a5b2f25112d9cea19d914da6d33844&view=Indexing">counterpoint-indexer.eth</a> created on Ethereum mainnet</li>
                    <li className="list-group-item">2022-12-24 Allocated first stake on mainnet</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col"><h3>&nbsp;</h3></div>
          </div>
      </div>
    </div>
  );
}

export default TheGraph;
