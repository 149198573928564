import '../App.css';
import subspaceLogo from '../images/subspace/subspace-logo-full.png';

function Subspace() {
  return (
    <div className="App-background-colour">
      <div className="container App-background-colour text-white">
          <div className="row">
            <div className="col"><h1>&nbsp;</h1></div>
          </div>
          <div className="row">
            <div className="col text-center">
              <a href="https://subspace.network">
                <img className="project-logo" src={subspaceLogo} alt="Subspace Network" width="400px" />
              </a>
            </div>
          </div>
          <div className="row">
            <div className="col"><h3>&nbsp;</h3></div>
          </div>
          <div className="row">
            <div className="col text-center">
              <h4>counterpoint are very proud to be participating in the Subspace Network</h4>
            </div>
          </div>
          <div className="row">
            <div className="col"><h3>&nbsp;</h3></div>
          </div>
          <div className="row">
            <div className="col">
              <div className="card text-black text-center">
                <div className="card-header">
                  <h3 className="card-title">Achievements so far</h3>
                </div>
                <div className="card-body">
                  <ul className="list-group">
                    <li className="list-group-item">Q2 2022 Participated in the <a className="link-primary" href="https://polkadot.js.org/apps/?rpc=wss%3A%2F%2Ffarm-rpc.subspace.network#/accounts">Aries</a> stress test</li>
                    <li className="list-group-item">Q2/3 2022 Participated in the first phase of the <a className="link-primary" href="https://polkadot.js.org/apps/?rpc=wss%3A%2F%2Feu.gemini-1b.subspace.network%2Fws#/accounts">Gemini</a> incentivised testnet</li>
                    <li className="list-group-item">Testnet reward address:</li>
                    <li className="list-group-item">Substrate format: 5DAvP1EJEhR8EE6NcJ1kEfkx9W9awjCRiEz64riJGD7SaE1c</li>
                    <li className="list-group-item">Subspace SS58 format: st7NQTVwkwMsLyr4xUTfESrF1t7Y3tRggjWTpMQgb48SYQMeS</li>
                    <li className="list-group-item">Q3 2022 Invited to become an apprentice ambassador</li>
                    <li className="list-group-item">2022-09-20 Passed apprenticeship and became a full ambassador</li>
                    <li className="list-group-item">2022-09-27 Namechecked (twice!) in the <a className="link-primary" href="https://medium.com/subspace-network/welcome-back-to-our-monthly-r-d-updates-subspace-network-683e239d625c">R&amp;D Update | September 2022</a> for <a className="link-primary" href="https://github.com/counterpointsoftware/subspace-monitoring">community contributions</a> -  a proud moment!</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col"><h3>&nbsp;</h3></div>
          </div>
      </div>
    </div>
  );
}

export default Subspace;
