import React from 'react';
import logo from './logo.svg';
import subQueryLogo from './images/subquery/subquery-logo-light-text.svg';
import subspaceLogo from './images/subspace/subspace-logo-full.png';
import theGraphLogo from './images/thegraph/thegraph-email-logo.png';
import celestiaLogo from './images/celestia/celestia-logo-black.svg';
import './App.css';

function App() {
  return (
    <div className="App-background-colour">
      <div className="container">
        <div className="App">
          <header className="App-header">
            <img src={logo} className="App-logo" alt="logo" />
            <p>
              <code>counterpoint software</code>
            </p>
            <p>
              <code>Providing software development and DevOps services to Web3 and blockchain projects</code>
            </p>
            <p>Current projects:&nbsp;               
              <a href="/subquery">
                <img src={subQueryLogo} className="App-project-logo" alt="SubQuery Network" />
              </a>
              &nbsp;
              <a href="/subspace">
                <img src={subspaceLogo} className="App-project-logo" alt="Subspace Network" />
              </a>
              &nbsp;
              <a href="/thegraph">
                <img src={theGraphLogo} className="App-project-logo" alt="The Graph Network" />
              </a>
              &nbsp;
              <a href="/celestia">
                <img src={celestiaLogo} className="App-project-logo" alt="Celestia Network" />
              </a>
            </p>
            <a
              className="App-link"
              href="mailto:info@counterpoint.software?subject=Website Contact"
              target="_blank"
              rel="noopener noreferrer"
            >
              Get in touch
            </a>
          </header>
        </div>
      </div>
    </div>
  );
}

export default App;
