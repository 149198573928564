import '../App.css';
import celestiaLogo from '../images/celestia/celestia-logo-black.svg';

function Celestia() {
  return (
    <div className="App-background-colour">
      <div className="container App-background-colour text-white">
          <div className="row">
            <div className="col"><h1>&nbsp;</h1></div>
          </div>
          <div className="row">
            <div className="col text-center">
              <a href="https://celestia.org">
                <img className="project-logo" src={celestiaLogo} alt="Celestia" width="400px" />
              </a>
            </div>
          </div>
          <div className="row">
            <div className="col"><h3>&nbsp;</h3></div>
          </div>
          <div className="row">
            <div className="col text-center">
              <h4>counterpoint are very proud to have participated as a mainnet validator on the Celestia network</h4>
            </div>
          </div>
          <div className="row">
            <div className="col"><h3>&nbsp;</h3></div>
          </div>
          <div className="row">
            <div className="col">
              <div className="card text-black text-center">
                <div className="card-header">
                  <h3 className="card-title">Achievements so far</h3>
                </div>
                <div className="card-body">
                  <ul className="list-group">
                    <li className="list-group-item">2022 Ran a testnet validator on both Mamaki and its forerunner</li>
                    <li className="list-group-item">2022 - 2024 Ran a <a className="link-primary" href="https://testnet.mintscan.io/celestia-testnet/validators/celestiavaloper1q4dum94p9xcp4kg4xcdphr3f69uycw7szxppl7">validator</a> on the Mocha test network</li>
                    <li className="list-group-item">2023-01-18 Invited to participate in the Celestia incentivised testnet</li>
                    <li className="list-group-item">2023-03-07 Celestia incentivised testnet - <a className="link-primary" href="https://blog.celestia.org/the-blockspace-race/">The Blockspace Race</a> starts</li>
                    <li className="list-group-item">2023-05-15 Blockspace Race <a className="link-primary" href="https://blog.celestia.org/a-blockspace-odyssey/">ends</a> with counterpoint in <a className="link-primary" href="https://docs.google.com/spreadsheets/d/1NIosukUeILs_Lmdq-g_bN5NDUSiq_SIw_JgOf2UQe9k/edit#gid=0">position 54/100</a></li>
                    <li className="list-group-item">2023-10-31 counterpoint proud to become a genesis Celestia <a className="link-primary" href="https://celestia.explorers.guru/validator/celestiavaloper1vfydl5r98zev8xc7j0mus28r63jcklsu63vuah">validator</a>!</li>
                    <li className="list-group-item">2024-03-31 We have taken the decision to shut our validator down to concentrate on other projects. We'd like to thank the Celestia team for the massive learning opportunity and our delegators while we were active on mainnet. Thank you.</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col"><h3>&nbsp;</h3></div>
          </div>
      </div>
    </div>
  );
}

export default Celestia;
