import subQueryLogo from '../images/subquery/subquery-logo-light-text.svg';
import '../App.css';

function SubQuery() {
  return (
    <div className="App-background-colour">
      <div className="container App-background-colour text-white">
          <div className="row">
            <div className="col"><h1>&nbsp;</h1></div>
          </div>
          <div className="row">
            <div className="col text-center">
              <a href="https://subquery.network">
                <img className="project-logo" src={subQueryLogo} alt="SubQuery Network" />
              </a>
            </div>
          </div>
          <div className="row">
            <div className="col"><h3>&nbsp;</h3></div>
          </div>
          <div className="row">
            <div className="col text-center">
              <h4>counterpoint are very proud to be participating in the SubQuery Network</h4>
            </div>
          </div>
          <div className="row">
            <div className="col"><h3>&nbsp;</h3></div>
          </div>
          <div className="row">
            <div className="col">
              <div className="card text-black text-center">
                <div className="card-header">
                  <h3 className="card-title">Achievements so far</h3>
                </div>
                <div className="card-body">
                  <ul className="list-group">
                    <li className="list-group-item">Passed The <a className="link-primary" href="https://doc.subquery.network/academy/herocourse/">Hero Course</a> in January 2022</li>
                    <li className="list-group-item">Frontier testnet season 2: finished in joint first position</li>
                    <li className="list-group-item"><a className="link-primary" href="https://explorer.subquery.network/subquery/subquery/leaderboard-s2">Subquery Network Leaderboard Season 2</a></li>
                    <li className="list-group-item">Frontier testnet season 3: finished in joint first position</li>
                    <li className="list-group-item"><a className="link-primary" href="https://explorer.subquery.network/subquery/subquery/subquery-network-leaderboard-season-3">Subquery Network Leaderboard Season 3</a></li>
                    <li className="list-group-item"><a className="link-primary" href="https://frontier.subquery.network/missions/ranks/indexer">Leaderboard Subquery Network Season 3</a></li>
                    <li className="list-group-item">Frontier indexer address (can be searched on the leaderboard above): 0x82aADA6f7ADedC1B68bdFA40D09581d6bB65eF76</li>
                    <li className="list-group-item">2023-05-01 Built our Kepler indexer which is the first step towards the decentralised mainnet!</li>
                    <li className="list-group-item">2023-05-31 Invited delegation on our <a className="link-primary" href="https://kepler.subquery.network/indexer/0x82aADA6f7ADedC1B68bdFA40D09581d6bB65eF76">Kepler indexer</a> after a month of testing and tweaking to ensure we are happy with the setup</li>
                    <li className="list-group-item">2024-02-23 After holding the top indexer ranking throughout most of the Kepler network we span up our <a className="link-primary" href="https://app.subquery.network/indexer/0x82aADA6f7ADedC1B68bdFA40D09581d6bB65eF76">mainnet indexer</a> and started indexing the mainnet projects</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col"><h3>&nbsp;</h3></div>
          </div>
          <div className="row">
            <div className="col">
              <div className="card text-black text-center">
                <div className="card-header">
                  <h3 className="card-title">Future plans</h3>
                </div>
                <div className="card-body">
                  <ul className="list-group">
                    <li className="list-group-item">We are committed to the project and are aiming to be a genesis indexer</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col"><h3>&nbsp;</h3></div>
          </div>
          <div className="row">
            <div className="col text-center">
              <h4>Why use counterpoint to serve your queries? Why should I delegate to counterpoint?</h4>
            </div>
          </div>
          <div className="row">
            <div className="col"><h3>&nbsp;</h3></div>
          </div>
          <div className="row">
            <div className="col">
              <div className="card text-black text-center">
                <div className="card-header">
                  <h3 className="card-title">Experience</h3>
                </div>
                <div className="card-body">
                  <ul className="list-group">
                    <li className="list-group-item">20 years enterprise development experience with a focus on back-end processing and building performant database applications for high availability and throughput</li>
                    <li className="list-group-item">Active in the <a className="link-primary" href="https://forum.subquery.network">SubQuery forum</a> and <a className="link-primary" href="https://discord.gg/abum7WrFPv">Discord server</a> with a history of providing technical support and bug discovery/reporting</li>
                    <li className="list-group-item">Experience in other indexing protocols</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col"><h3>&nbsp;</h3></div>
          </div>
          <div className="row">
            <div className="col">
              <div className="card text-black text-center">
                <div className="card-header">
                  <h3 className="card-title">Infrastructure</h3>
                </div>
                <div className="card-body">
                  <ul className="list-group">
                    <li className="list-group-item">We use a number of providers including Hetzner and Azure with servers throughout Europe to enable colocation and redundancy</li>
                    <li className="list-group-item">Investment in infrastructure which is carefully monitored to ensure there is always headroom to handle whatever the query market throws at us</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col"><h3>&nbsp;</h3></div>
          </div>
          <div className="row">
            <div className="col">
              <div className="card text-black text-center">
                <div className="card-header">
                  <h3 className="card-title">Service level agreement (SLA)</h3>
                </div>
                <div className="card-body">
                  <ul className="list-group">
                    <li className="list-group-item">Our hardware estate and software stack is monitored using industry leading tools</li>
                    <li className="list-group-item">Monitoring is hooked up to alerting channels in Discord and Telegram which consumers and delegators are welcome to join</li>
                    <li className="list-group-item">Our aim is that service affecting alerts will be assessed and responded to within 1 hour between 09:00 and 23:00 UTC and statistics pulled together to provide historical response times</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col"><h3>&nbsp;</h3></div>
          </div>
          <div className="row">
            <div className="col">
              <div className="card text-black text-center">
                <div className="card-header">
                  <h3 className="card-title">Historical performance</h3>
                </div>
                <div className="card-body">
                  <ul className="list-group">
                    <li className="list-group-item">So far we only have testnet performance to go on to demonstrate our proficiency as a SubQuery indexer - we came joint 1st position in 2 seasons!</li>
                    <li className="list-group-item">We are committed to transparency so performance statistics will be collected and made available publicly - the good and the bad</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col"><h3>&nbsp;</h3></div>
          </div>
          <div className="row">
            <div className="col">
              <div className="card text-black text-center">
                <div className="card-header">
                  <h3 className="card-title">Contact us</h3>
                </div>
                <div className="card-body">
                  <ul className="list-group">
                    <li className="list-group-item"><a className="link-primary" href="https://discord.gg/kxySShbevJ">Discord</a></li>
                    <li className="list-group-item"><a className="link-primary" href="#">Telegram</a></li>
                    <li className="list-group-item"><a className="link-primary" href="mailto:info@counterpoint.software">email</a></li>
                    <li className="list-group-item"><a className="link-primary" href="https://pacindexer.freecluster.eu">A little easter egg for reading this far :)</a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col"><h3>&nbsp;</h3></div>
          </div>
      </div>
    </div>
  );
}

export default SubQuery;
